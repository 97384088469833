<template>
  <div>
    <p class="text-2xl mb-6">
      {{ editing ? `Editer le site : ${site.name}` : "Créer un nouveau Site" }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="site.parent_id"
                  :items="siteList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Site Parent"
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="site.area_id"
                  :items="siteAreaList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Zone"
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="site.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="site.code"
                    outlined
                    dense
                    :rules="[required]"
                    label="Identifiant"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="site.shortname"
                    outlined
                    dense
                    :rules="[required]"
                    label="Trigramme"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="site.domain"
                    dense
                    label="Nom de domaine"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="site.brand_id"
                  :items="siteBrandList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Marque"
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="site.gaviewid"
                    dense
                    label="Id de la vue GA"
                    :rules="[integerValidator]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-switch
                    v-model="site.isga4"
                    :label="`vue GA4 : ${site.isga4 ? 'Oui' : 'Non'}`"
                    outlined
                    dense
                  ></v-switch>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="site.wyzistat_site_id"
                    dense
                    label="Id Wyzistat"
                    :rules="[integerValidator]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveSite"
                >
                  Enregistrer le Site</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'admin-list-site' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";

export default {
  name: "SiteCreateOrUpdate",
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      siteAreaList: [],
      siteBrandList: [],
      siteList: [],
      site: {
        pk: -1,
        parent_id: 0,
        name: "",
        code: "",
        shortname: "",
        domain: "",
        gaviewid: "",
        isga4: false,
        wyzistat_site_id: 0,
        area_id: 0,
        brand_id: 0,
      },
    };
  },
  async created() {
    if ("id" in this.$route.params && this.$route.params.id) {
      this.getSiteById(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
    this.siteAreaList = await this.$store.dispatch("common/getSiteAreaList");
    this.siteBrandList = await this.$store.dispatch("common/getSiteBrandList");
    this.siteList = await this.$store.dispatch("common/getSiteList");
  },
  methods: {
    async saveSite() {
      const isFormValid = this.$refs.siteForm.validate();
      if (!isFormValid) return;

      const wyzistatSiteId = parseInt(this.site.wyzistat_site_id);
      const payload = {
        ...this.site,
        parent_id: this.site.parent_id > 0 ? this.site.parent_id : null,
        area_id: this.site.area_id > 0 ? this.site.area_id : null,
        brand_id: this.site.brand_id > 0 ? this.site.brand_id : null,
        ga_view_id: this.site.gaviewid,
        is_ga4: this.site.isga4,
        wyzistat_site_id: wyzistatSiteId ? wyzistatSiteId : null,
      };

      if (this.editing) {
        console.log("saveSite", this.editing, `/sites/${this.site.pk}`);
        const { status } = await axios.put(`/sites/${this.site.pk}`, payload);
        if (status === 200) {
          await router.push({ name: "admin-list-site" });
        }
      } else {
        const { status } = await axios.post("/sites/", payload);
        if (status === 200) {
          await router.push({ name: "admin-list-site" });
        }
      }
    },
    async getSiteById(id) {
      const { status, data } = await axios.get(`/sites/${id}`);
      if (status === 200) {
        this.site = {
          ...data,
          pk: data.id,
          gaviewid: data.ga_view_id,
          isga4: data.is_ga4,
        };
      }
    },
    normalize(s) {
      return s
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-z0-9 -]/gi, "")
        .replace(/[\s.*]/gi, "");
    },
  },
  watch: {
    name(v) {
      this.code = this.normalize(v);
      this.shortname = this.normalize(v)
        .replace(/[aeiou]/gi, "")
        .substring(0, 5);
    },
  },
};
</script>

<style scoped></style>
